import React, { useState } from 'react'
import { usePageNav } from '../../contexts/page-nav'
import './index.scss'
import serviceMethods from '../../service'
import { useRef } from "react"

const ContactForm = () => {
  const { scrollRef } = usePageNav()
  const formRef = useRef();
  const [disableButton, setDisableButton] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const { isFormSubmitted, setIsFormSubmitted } = usePageNav();
  const [errors, setErrors] = useState([])
  const EMAIL_REGEX = /\S+@\S+\.\S+/;

  const colorSetter = (s) => {
    if (errors.includes(s)) return "is-danger"
    else return "is-link"
  }

  const emailColorSetter = (s1, s2) => {
    if (errors.includes(s1) || errors.includes(s2)) return 'is-danger'
    else return 'is-link'
  }

  const showSuccessBox = async () => {
    setTimeout(() => {
      setShowSuccess(false)
    }, 4000)
  }

  const submitForm = async (e) => {
    e.preventDefault()
    setDisableButton(true)
    const { formCompany, formFirstName, formLastName, formEmail, formPhone, formMessage } = e.target.elements
    let formData = {
      company: formCompany.value,
      firstName: formFirstName.value,
      lastName: formLastName.value,
      email: formEmail.value,
      phone: formPhone.value,
      message: formMessage.value
    }
    let errorMessages = []
    if (!formData.firstName) {
      errorMessages.push('First name required!')
    }
    if (!formData.lastName) {
      errorMessages.push('Last name required!')
    }
    if (!formData.email) {
      errorMessages.push('Email address required!')
    }
    if (!EMAIL_REGEX.test(formData.email)) {
      errorMessages.push('Email address invalid!')
    }
    if (!formData.message) {
      errorMessages.push('Message required!')
    }
    //success logic
    if (errorMessages.length === 0) {
      const res = await serviceMethods.submitContactForm(formData)
      if (res.ok) {
        const { message } = await res.json()
        if (message === 'contactForm-create-success') {
          setIsFormSubmitted(true)
          formRef.current.reset()
          setShowSuccess(true)
          await showSuccessBox()
        } else {
          errorMessages.push('Something went wrong...')
        }
      }
    }
    setErrors(errorMessages)
    setDisableButton(false)
  }
  return (
    <>
      <p ref={scrollRef} className="has-text-weight-semibold is-size-5">
        Launch your message into our orbit and let's collaborate on your unique project.
      </p>
      {errors.length > 0 &&
        <div className="notification" id="errorBox">
          <div className="columns is-vcentered is-mobile">
            <div className="column is-narrow">
              <img src="X-indicator.png" />
            </div>
            <div className="column">
              <p className="has-text-weight-bold errorBoxText headlineFont">Error</p>
              {errors.map((error, i) => <p className="errorBoxText messageFont" key={`errors-${i}`}>{error}</p>)}
            </div>
          </div>
        </div>
      }
      {showSuccess && (
        <div className="notification is-success is-light" id="successBox">
          <div className="columns is-vcentered is-mobile">
            <div className="column is-narrow">
            </div>
            <div className="column">
              <p className="has-text-weight-bold headlineFontSuccess">Thank you, your message has been received!</p>
            </div>
          </div>
        </div>
      )}
      <form
        onSubmit={submitForm} ref={formRef}>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <div className="control has-floating-label">
                <input className={`input is-medium with-floating-label is-link`} name="formCompany" type="text" id="companyInput" placeholder="" />
                <label className="label is-floating-label" htmlFor="companyInput">Company/Organization</label>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="control has-floating-label">
                <input className={`input is-medium with-floating-label ${colorSetter('First name required!')}`} id="fNameInput" name="formFirstName" type="text" placeholder=""></input>
                <label className="label is-floating-label" htmlFor="fNameInput">*First name</label>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <div className="control has-floating-label">
                <input className={`input is-medium with-floating-label ${colorSetter('Last name required!')}`} id="lNameInput" name="formLastName" type="text" placeholder="" />
                <label className="label is-floating-label" htmlFor="lNameInput">*Last name</label>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="control has-floating-label">
                <input className={`input is-medium with-floating-label ${emailColorSetter('Email address required!', 'Email address invalid!')}`} id="emailInput" name="formEmail" type="text" placeholder="" />
                <label className="label is-floating-label" htmlFor="emailInput">*Email</label>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <div className="control has-floating-label">
                <input className="input is-medium with-floating-label is-link" id="phoneInput" name="formPhone" type="text" placeholder="" />
                <label className="label is-floating-label" htmlFor="phoneInput">Phone</label>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="control has-floating-label">
                <textarea className={`textarea is-medium with-floating-label ${colorSetter('Message required!')}`} name="formMessage" id="messageInput" placeholder="" />
                <label className="label is-floating-label" htmlFor="messageInput">*Message</label>
              </div>
            </div>
          </div>
        </div>
        <div className="columns is-centered is-mobile">
          <div className="column is-narrow">
            <button className="button is-rounded is-warning mt-3" type="submit" disabled={disableButton}>Let's blast off!</button>
          </div>
        </div>
      </form>
    </>
  )
}


export default ContactForm;
